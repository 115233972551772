module App

open Feliz
open Elmish
open Feliz.UseDeferred
open Feliz.Bulma

type State = { Count: int }

// type ApiValue = { name: string; url: string }

// type PokemonResult = { results: ApiValue list }


open Fable.Remoting.Client
open Test.Shared
open Fable.SignalR.Feliz
open Fable.SignalR

let inboxStore: IInbox =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder (APIUrls.routeBuilder APIUrls.Root)
    |> Remoting.buildProxy<IInbox>


type Msg =
    | Increment
    | Decrement

let init () = { Count = 0 }, Cmd.none

let update (msg: Msg) (state: State) =
    match msg with
    | Increment -> { state with Count = state.Count + 1 }, Cmd.none
    | Decrement -> { state with Count = state.Count - 1 }, Cmd.none

// let render (state: State) (dispatch: Msg -> unit) =
//     Html.div [ Html.button [ prop.onClick (fun _ -> dispatch Increment)
//                              prop.text "Increment" ]

//                Html.button [ prop.onClick (fun _ -> dispatch Decrement)
//                              prop.text "Decrement" ]

//                Html.h1 state.Count ]


let renderSingle =
    React.functionComponent
        ("SingleListDeferred",
         (fun (input: {| prefix: string |}) ->

             let (isVisible, viewDetails) = React.useState (false)

             let loadData =
                 async {
                     let! list = inboxStore.listDirectory input.prefix
                     //  do! Async.Sleep 1500
                     return list
                 }

             let (data, setData) =
                 React.useState (Deferred.HasNotStartedYet)

             //let startLoadingData =
             //       React.useDeferredCallback ((fun () -> loadData), setData)

             let startLoad =
                 React.useDeferredCallback ((fun () -> loadData), setData)

             Html.div [ Bulma.button.a [ button.isFullWidth
                                         if isVisible then color.isPrimary
                                         prop.text input.prefix
                                         prop.onClick (fun _ ->
                                             let visibility = not isVisible
                                             viewDetails visibility
                                             if visibility then startLoad ()) ]
                        match isVisible with
                        | false -> Html.none
                        | true ->
                            match data with
                            | Deferred.HasNotStartedYet -> Html.none
                            | Deferred.InProgress ->
                                Bulma.hero [ Bulma.heroBody [ text.hasTextCentered
                                                              prop.children [ Html.div "loading..." ] ] ]
                            //Bulma.icon [ icon.isLarge
                            //             prop.children [ Html.i [ prop.className [ "fas"
                            //                                                       "fa-sync-alt"
                            //                                                       "fa-spin"
                            //                                                       "fa-2x" ] ] ] ]                                                                                                       "fa-2x" ] ] ] ] ] ] ]
                            | Deferred.Failed error -> Html.div error.Message

                            | Deferred.Resolved content ->
                                Bulma.table [ table.isBordered
                                              table.isHoverable
                                              table.isFullWidth
                                              table.isNarrow
                                              table.isStriped
                                              prop.children [ Html.tbody [ prop.children [ for item in content do
                                                                                               Html.tr [ Html.td item ] ] ] ] ] ]

         ))

open Fable.SimpleJson

let renderList =
    React.functionComponent
        ("ListDeferred",
         (fun () ->
             let loadData =
                 async {
                     let! list = inboxStore.list ()
                     //  do! Async.Sleep 1500
                     return list
                 }

             let (data, setData) =
                 React.useState (Deferred.HasNotStartedYet)

             let startLoadingData =
                 React.useDeferredCallback ((fun () -> loadData), setData)

             React.useEffect (startLoadingData, [||])


             let hub =
                 React.useSignalR<Action, string> (fun hub ->
                     //let api = "/SignalR"
                     //let api = "http://localhost:5000/SignalR"
                     let api = APIUrls.Root
                     hub.withUrl(api)
                        //.useMessagePack()
                        .withAutomaticReconnect().configureLogging(LogLevel.Debug).onMessage
                     <| (Fable.SimpleJson.Json.parseAs<Response>
                         >> function
                         | Response.NewCount i -> ()
                         | Response.RandomCharacter str -> printfn "%s" str
                         | Response.NewMail -> startLoadingData ())

                     )

             Bulma.section [ match data with
                             | Deferred.HasNotStartedYet -> Html.none
                             | Deferred.InProgress ->
                                 Bulma.container [ Bulma.hero [ Bulma.heroBody [ text.hasTextCentered
                                                                                 prop.children [ Bulma.icon [ icon.isLarge
                                                                                                              prop.children [ Html.i [ prop.className [ "fas"
                                                                                                                                                        "fa-sync-alt"
                                                                                                                                                        "fa-spin"
                                                                                                                                                        "fa-2x" ] ] ] ] ] ] ] ]
                             | Deferred.Failed error -> Html.div error.Message

                             | Deferred.Resolved content ->
                                 Bulma.container [ for item in content do
                                                       renderSingle {| prefix = item |} ]

                              ]))

let render (state: State) (dispatch: Msg -> unit) = renderList ()
